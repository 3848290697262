import { useEffect, useState } from "react";
import "./Experience.scss"
import experienceItems from "./Experience-items";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function Experience() {
  
  const items = experienceItems;

  const Card = (props:any) => {
    return (
      <li key={props.key} className="card">
        <h3>{props.company}</h3>
        <h4>{props.jobTitle}</h4>
        <h5>{props.date}</h5>
        <p>{props.copy}</p>
        <p>{props.copy2}</p>
        <p>{props.copy3}</p>
        <p>{props.copy4}</p>
        <p>{props.copy5}</p>
      </li>
    )
  }
  const [carouselItems, setCarouselItems] = useState(items);
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      centerMode: true,
      slidesToShow: 4,
      arrows: false,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            initialSlide: 1
          }
        }
      ]
    };

    return (
      <div id="experience" className="experience">
        <div className="experience__inner">
          <h2>EXPERIENCE</h2>
        </div>
          <Slider {...settings}>
              {carouselItems.map((t, index) => 
                  <Card key={index} company={t.company} jobTitle={t.jobTitle} date={t.date} copy={t.copy} copy2={t.copy2} copy3={t.copy3} copy4={t.copy4} copy5={t.copy5}/>
                )}
          </Slider>
      </div>
    );
  }
  
  export default Experience;
  