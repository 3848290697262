import { useEffect } from 'react';
import './App.scss';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Experience from './components/Experience/Experience';
import Menu from './components/Menu/Menu';
import Skills from './components/Skills/Skills';
import Skillset from './components/Skillset/Skillset';
import Clients from './components/Clients/Clients';
import UseCases from './components/Use-Cases/UseCases';

/** TO DO MOVE ALL INTO INDEX */

function App() {
  return (
    <div className="app">
      <Menu/>
      <About/>
      <Experience/>
      <Skillset/>
      <Clients/>
      <UseCases/>
      <Skills/>
      {/* <Contact/> */}
      <div className="footer">All Rights Reserved 2023</div>
    </div>
  );
}

export default App;
