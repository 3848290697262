import "./Clients.scss";
import awsLogo from "./../../assets/aws-logo.png";
import bmwLogo from "./../../assets/bmw-group-logo.png";
import clpLogo from "./../../assets/clp-logo.png";
import deadalicLogo from "./../../assets/daedalic-logo.png";
import googleLogo from "./../../assets/google-logo.png";
import hsbcLogo from "./../../assets/hsbc-logo.png";
import investHKlogo from "./../../assets/investHK-logo.png";
import luxotticaLogo from "./../../assets/luxottica-logo.png"; 
import microsoftLogo from "./../../assets/microsoft-logo.png";
import oculusLogo from "./../../assets/oculus-logo.png";
import swireLogo from "./../../assets/swire-logo.png";
import wppLogo from "./../../assets/wpp-logo.png"; 
function Clients() {
  return (
      <div id="clients" className="clients">
        <div className="clients__inner">
        <div className="clients__text">
          <h2>WORKED WITH</h2>
        </div>
        <ul className="clients__list">
          <li className="clients__list__item">
            <img src={awsLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={microsoftLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={googleLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={oculusLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={wppLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={bmwLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={clpLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={deadalicLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={swireLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={hsbcLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={luxotticaLogo} alt="placeholder" width="100%" height="100%"/>
          </li>
          <li className="clients__list__item">
            <img src={investHKlogo} alt="placeholder" width="100%" height="100%"/>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Clients;