import { useEffect, useState } from "react";
import "./Menu.scss"
import githubLogo from "./../../assets/github-logo.png";
import linkedinLogo from "./../../assets/linkedin-logo.png";
function Menu() {
  const [menu, menuState] = useState(0);

  /**
   * opens and closes the menu
   */
  const OpenMenu = () :void=> {
    const menuSection = document.querySelector(".menu__container")!
    const navIcon = document.querySelector("#nav-icon")!
    if (menu  === 0) {
      menuSection.classList.add("open-menu");
      navIcon.classList.add("open")
      menuState(1);
    } else {
      menuSection.classList.remove("open-menu")
      navIcon.classList.remove("open")
      menuState(0);
    }
  }

  // scroll to menu element
  const ScrollTo = (targetElement: string):void => {
      const element = document.getElementById(targetElement);
      if (element) {
        element.scrollIntoView({ behavior: "smooth"});
        OpenMenu();
      }
  }

  return (
    <div className="menu">
      <nav className="menu__nav">
        <div id="nav-icon" className="menu__icon" onClick={OpenMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="menu__container">
          <ul className="menu__list">
            <li className="menu__item">
              <button className="menu__link" onClick={() => ScrollTo("about")}>About</button>
            </li>
            <li className="menu__item">
              <button className="menu__link" onClick={() => ScrollTo("experience")}>Experience</button>
            </li>
            <li className="menu__item">
              <button className="menu__link" onClick={() => ScrollTo("skillset")}>Skillset</button>
            </li>
            <li className="menu__item">
              <button className="menu__link" onClick={() => ScrollTo("clients")}>Clients</button>
            </li>
            <li className="menu__item">
              <button className="menu__link" onClick={() => ScrollTo("use-cases")}>Use Cases</button>
            </li>
            <li className="menu__item">
              <button className="menu__link" onClick={() => ScrollTo("skills")}>Skills</button>
            </li>
            {/* <li className="menu__item">
              <button className="menu__link" onClick={() => ScrollTo("contact")}>Contact</button>
            </li> */}
          </ul>
          <ul className="menu__external">
            <li>
              <a href="https://www.linkedin.com/in/akapumkin/" target="_blank" rel="noopener noreferrer"></a>
              <img src={linkedinLogo} alt="" width="100%" height="100%"/>
            </li>
            <li>
              <a href="https://github.com/AKApumkin" target="_blank" rel="noopener noreferrer"></a>
              <img src={githubLogo} alt="" width="100%" height="100%"/>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Menu;
