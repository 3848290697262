function ExperienceItems() {
    const experienceItems = [
        {
            company:"Smart Energy Connect",
            jobTitle:"Technical Lead",
            date:"Oct 2021 - March 2023",
            copy:"Collaborated with cross-functional teams to design and implement scalable and efficient software architectures for smart energy solutions in the cloud with AWS.",
            copy2:"Developed responsive and user-friendly web applications that provided real-time insights into energy consumption and sustainability efforts for clients, with ReactJS and NodeJS.",
            copy3:"Conducted both frontend and backend development, ensuring seamless integration and optimal user experiences through automated deployments on CI/CD pipelines.",
            copy4:"Implemented robust data collection, storage, and analysis mechanisms to process and leverage data from IoT devices and sensors leveraging message broker services through pub/sub architecture.",
            copy5:"Continuously optimized software performance by identifying bottlenecks and implementing solutions for improved efficiency. This was achieved by using tools such as google lighthouse to identify code specific areas that needed improvement."
        },{
            company:"Mirum Agency Hong Kong (WPP comapny)",
            jobTitle:"Business Technology Lead (Consulting)",
            date:"Jun 2019 - May 2021",
            copy:"Created responsive, dynamic and user friendly sites through Single Page Application such as ReactJS, leveraging headless CMS architecture and RESTful API design.",
            copy2:"Built Content Management systems in Laravel and other PHP frameworks for clients to manage their own website and app content.",
            copy3:"Secured application through enterprise security scans such as Microfus’s Fortify on Demands and Detectify. In addition to following industry standards on the OWASP top 10.",
            copy4:"Constructed small scale API’s and microservices through NodeJS for various IoT projects and message broker services.",
            copy5:"Designed and maintained robust code standards through swagger documentation, building linters to enforce code standards and Github workflows to ensure work produced by the various teams was fit for production."
        },{
            company:"Mirum Agency Hong Kong (WPP comapny)",
            jobTitle:"Senior Analyst Software Developer",
            date:"Jun 2017 - May 2019",
            copy:"For 2 years I worked as a Senior Analyst focusing on pipeline and stack optimizations throughout the company’s different development departments. Mirum Hong Kong has over 40 developers covering Mobile, Front-end, Back-end and Operations. My first task was to bring in as much automation as possible using build tools and frameworks such as, gulp, docker, Jenkins. Once the basics were in place, I began to look at how the business and creative departments also interacted with the development teams and began to improve communication, documentation and training to help reduce friction during the lifestyle of different projects.",
            copy2:"Towards the end of the 2 years I began to focus on more on security related tasks that helped automate and protect the different teams. I provided oversight, process and implementation of various security methodologies and practices. This included security and technology architecture design. Through this I had to interact with clients directly and became more client facing in my role."
        },{
            company:"Fitch (WPP comapny)",
            jobTitle:"Senior Analyst Software Developer",
            date:"Feb 2017 - May 2017",
            copy:"I undertook a three-month contract to assist with the surge in workload related to corporate products from Fitch's clients. During this period, I collaborated closely with both the Front and Back end teams, contributing to the creation of seamless user experiences and endpoints.",
            copy2:"Leveraging the BEM methodology, I crafted user-friendly designs in alignment with the functional specifications, ensuring they met and exceeded client expectations. One of the highlights of my role involved working with Sitecore within the .NET framework, where I actively participated in building a robust client API."
        },{
            company:"Virtual Gaming Limited",
            jobTitle:"Front-end Web Analyst Programmer",
            date:"Aug 2016 - Feb 2017",
            copy:"I worked to research and develop front end techniques, frameworks and methodologies to support the development of an single page application for the company. Through the use of Angualr and Selenium webdriver. As well as other external API's"
        },{
            company:"AKAstudios, Hong Kong Adventure Tours Limited, MetricQR",
            jobTitle:"Managing Director",
            date:"Aug 2012 - Jan 2016",
            copy:"For four years I ran my own set of companies which were all focused on software services for clients in Hong Kong. This experience saw me exposed to all the requirements of an organization, everything from marketing, client services, finance, business development, web development and more. I have carried this ability to wear many hats into all the roles I have been in since and have maintained a passion for focus on client and business value through whatever I have done."
        }
      ];
      return experienceItems;
}
export default ExperienceItems;