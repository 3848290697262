import { useState } from "react";
import "./UseCases.scss";
import useCases from "./UseCasesContent";

function UseCases() {
    const [cases, setCases] = useState(useCases);
    
    const UseCaseItem = (props:any) => {
        return (
        <li>
            <div className="use-cases__item__text">
                <h3>The Ask</h3>
                <p>{props.ask}</p>
                <h3>The Project</h3>
                <p>{props.project}</p>
                <h3>How it was made</h3>
                <p>{props.how}</p>
                <h3>Success</h3>
                <p>{props.success}</p>
            </div>
            <div className="use-cases__item__image">
                <img src={props.image} alt="" width="100%" height="100%" />
            </div>
        </li>
        )
    }

    return (
        <div id="use-cases" className="use-cases">
            <div className="use-cases__inner">
                <h2>USE CASES</h2>
                <section className="use-cases__item">
                    <ul>
                        {cases.map((t, index) => 
                        <UseCaseItem ask={t.ask} project={t.project} how={t.how} success={t.success} image={t.image} />
                        )}
                    </ul>
                </section>
            </div>
        </div>
        );
    }
export default UseCases;