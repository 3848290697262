import "./Skillset.scss";
import consutancyLogo from "./../../assets/consultancy.png";
import skillet from        "./../../assets/skillset.png";
function Skillset() {
    return (
      <div id="skillset" className="skillset">
        <div className="skillset__inner">
          <h2>SKILLSET</h2>
          <div className="skillset__container">
            <section className="skillset__section">
              <div className="skillset__section__image">
                  <img src={consutancyLogo} alt="" width="100%" height="100%"/>
              </div>
              <ul className="skillset__list">
                <li className="skillset__list__item">
                  <div className="skillest__list__text">
                  <h3 className="f-h3">Consultant</h3>
                    <p>
                    I conduct initial stage meetings and RFP briefs with clients. Together, we explore the business needs through interactive workshops and discovery sessions. These sessions identify pain points, and explore potential areas for improvement.
                    </p>
                    <p>
                    Translating these insights into a detailed scope of work and a comprehensive functional specification, these documents serve as a roadmap, outlining the specific tasks and objectives of the project. This detailed breakdown empowered my clients to tailor the project according to their budgetary constraints whilst maximizing value, ensuring that the proposed work aligns seamlessly with their financial needs.
                    </p>
                  </div>
                </li>
                <li className="skillset__list__item">
                  <h3 className="f-h3">Senior leadership</h3>
                  <p>
                  During my tenure at Mirum Agency, I had the privilege of collaborating directly with the CEO on transformative initiatives. Together, we significantly enhanced the hiring process by fostering a tech-centric culture within the company. 
                  </p>
                  <p>
                  My involvement extended to strategic areas such as budget allocation and innovative endeavors. These initiatives were linked to the company's PNL, emphasizing the critical impact they had on the company's financial health. Working closely on these fronts provided me with valuable insights into the intersection of technology, business strategy, and fiscal responsibility, expanding my professional experience in the process.
                  </p>
                </li>
                <li className="skillset__list__item">
                  <h3 className="f-h3">Security as practice</h3>
                  <p>
                  As a data protection officer I have an in-depth understanding of various data regulations, including GDPR, the Californian Data Protection Act, the Hong Kong Data Protection Ordinance and other regional data laws.
                  </p>
                  <p>
                  I have also been responsible for overseeing and executing penetration testing using advanced tools like Detectify and Fortify on Demand and other tools that follow the OWASP Top 10. 
                  </p>
                </li>
              </ul>
            </section>
            <section className="skillset__section">
              <div className="skillset__section__image second">
                <img src={skillet} alt="" width="100%" height="100%"/>
              </div>
              <ul className="skillset__list">
                <li className="skillset__list__item">
                  <h3 className="f-h3">Tech growth</h3>
                  <p>
                  Remaining at the forefront of the ever-evolving technology landscape has been a personal commitment of mine. In the dynamic realm of business; ideas, issues, and obstacles constantly undergo transformation. This perpetual change offers a plethora of opportunities to leverage new software, systems, hardware, and technology to address challenges, foster solutions, and catalyze business growth.
                  </p>
                  <p>
                  I am deeply passionate about exploring these innovations. Devoting a dedicated hour of my day to experimenting with cutting-edge technologies and practices. This deliberate effort allows me to stay ahead in the technology sector, ensuring that I am equipped with the knowledge and skills necessary to meet the evolving needs of the business landscape.
                  </p>
                </li>
                <li className="skillset__list__item">
                  <h3 className="f-h3">VR</h3>
                  <p>
                  I am deeply passionate about virtual reality (VR), proudly owning the Oculus 1 and 2, and soon to be the Oculus 3. My enthusiasm for VR extends beyond personal enjoyment; I firmly believe in its transformative potential and what it can accomplish.
                  </p>
                  <p>
                  Professionally, I have harnessed this passion by leading and architecting projects in the realm of learning applications. I had the opportunity to spearhead initiatives for the largest utility provider in Asia. In this capacity, VR and mixed reality emerged as a powerful tools, offering unparalleled value in training individuals to operate complex and hazardous equipment. VR provides an immersive and realistic training environment, which is essential when dealing with machinery that is both dangerous and expensive.
                  </p>
                  <p>
                  I am deeply committed to leveraging VR technology to its fullest extent, ensuring that individuals are trained effectively and safely, thereby aligning with the vision of VR and its immense potential in various sectors.
                  </p>
                </li>
              </ul>
            </section>
        </div>
      </div>
      </div> 
    );
};
export default Skillset;