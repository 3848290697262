import "./About.scss";
function About() {
    return (
      <div id="about" className="about">
        <div className="about__inner">
          <div className="about__text">	
          <h1>PATRICK WILLIAMS</h1>
          <h2>Technical Consultant | Programmer | VR Enthusiast</h2>
          </div>
        </div>
      </div>
    );
  }
  
  export default About;