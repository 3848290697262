import paymentPlatform from "./../../assets/payment-platform.png";
import brandLaunch from "./../../assets/brand-launch.png";
import geolocation from "./../../assets/geo-location.png";
import vcard from "./../../assets/v-card.png";
import cdnnetwork from "./../../assets/cdn-network.png";
import vr1 from "./../../assets/vr-1.png";
import vr2 from "./../../assets/vr-2.png";

function UseCases() {

    const useCases = [
        {
            client: "A",
            ask: "The brief was to rebuild and rehost a popular payment applications website as quickly as possible, whilst providing continual support to ad hoc campaigns and initiatives.",
            project: "Being given full control over such a large companies web facing portal, was a very daunting task, especially with all the regulation and service level agreement that comes with it. The first task was to architect a solution that would undergo an architectural board review on the client's side to ensure High availability and security were met to the highest standards. Most of this work rested on AWS with load balancers, health probes, secure DNS routing, rollbacks and failovers. Once this was achieved it was onto the web development portion.",
            how: "The client only needed to update one thing at any particular time, so no full blown CMS solutions was chosen, instead a very simple IP restricted and secure login system was created for one user to perform this action. Using PHP Laravel the whole system could be served, updated and controlled very quickly. To facilitate not only rapid development, but also deployment to any current or future environments, PHP laravel was rolled up into a docker container that would also help with automatic 0 downtime deployments later on down the road.",
            success: "To ensure SLA’s were kept, we had dedicated monitoring and support services that would kick off any time the application failed. I can happily report we maintained a 99.5% uptime for the project duration. In addition there were several ad hoc campaigns during the year long projects that I led. These were additional business and growth for both the company and the client. In addition there were several ad hoc campaigns during the year long projects that I led. These were additional business and growth for both the company and the client.",
            image: paymentPlatform
        },{
            client: "B",
            ask: "The brief was to completely refresh, re-brand and re-platform an existing site, bringing it up to date with modern trends and create an easier way for their current staff to update and modify existing content.",
            project: "Working with the design team, we crafted the front-facing application with a design guide that would be a blueprint for the whole site and make developing all the different pages, templates and layouts much easier. In addition we started to source a CMS system that could meet all the clients requirements for updating content.",
            how: "As the front end was to be integrated into the backend we needed to find a quick way to develop the front-end framework that allowed for fast revisions, and minimized the integration work. During my time at another company I had been introduced to the PUG templating engine, and through this would be a good fit. As we wanted to move as fast as possible the first task was to set up our own gulp framework to do the heavy lifting in pre-processing the code ready for the integration into the backend. For the back-end portion of the work we had decided on using Drupal, as it was something we knew the client could easily be trained on, and had all the functionality we needed for the integrations and additional services the client was already using. The final addition to the project was that, as it was for the government, it needed to pass a web accessibility test, specifically using the JAWS testing tool. As this was the first time we had taken on work that was going to be tested independently to a high level, we needed to conduct a lot of research on best practices and structure for the front-end portion of the code making sure it was WACG 2.1 compliant. ",
            success: "This project had two successes. The main success for us was to develop a new internal pipeline between the font-end and back-end, this definitely reduced so much time in development and UAT. And for the second success the project launched on time, to specification and passed the independent JAWS test.",
            image: brandLaunch
        },{
            client: "C",
            ask: "The brief was to launch a campaign website, inside a core region.",
            project: "This project was definitely a challenge, less of the technical and more of working with vendors inside the core region to get CDN’s and hosting provided to ensure as much coverage as possible. In addition to the legal obstacles of a western brand moving into the core region.",
            how: "The website was built in drupal with a very simple front-end end design. The main technical challenge was to ensure that all frameworks/code could be self hosted so as not to make external calls out of the core region.",
            success: "The client launched on the main selling website in-side the core region with additional promotion directed there through a campaign from the website we had built for them.",
            image: cdnnetwork
        },{
            client: "D",
            ask: "To build a geolocation dependent website, that would serve different content, depending on what region a user was located in and where they were located within that region.",
            project: "The crux of this project was ensuring that the system could serve different languages, in different locales to different users, all dependent on their language preference and location. The first main task was to architect the solutions to be one platform, website entry and CMS system. This was to be spread over all the different regions, sending data to different teams throughout the regions.",
            how: "As we had multiple departments on the client side, each with their own requirements involved on the project. We decided to make our own custom CMS, this was due to the fact, in addition to the Geo location, we also had some ID verification software and warranty workflows that needed to be implemented, whilst these could have been done in Drupal, we felt the long standing issue with database migrations within drupal might cause us issues down the line. So instead decided to make headless front-ends that connected to our own backend. This way we could also shut down individual regions if required, without affecting any other part of the system, as we would have control over database segregation. The other major challenge with this project was undergoing an enterprise security scan. This was more than we were expecting and did take us some considerable time in researching how to fix potential attack vectors and exploits, but the knowledge we gained on this definitely carried through to all our future projects.",
            success: "We knew we had success when we could start rolling out different regions as and when required in a very short period of time, due to our choice with the custom CMS. We were also able to easily manage multiple client testing environments as individual endpoints on different databases. This was also reflected in how happy the client was in our turnarounds for new regions being brought online.",
            image: geolocation
        },{
            client: "E",
            ask: "The brief was to help promote sustainability through going completely digital with business cards. The main dealerships give out 100’s of cards daily, adding a significant carbon footprint to the company's daily operations.",
            project: "Working with the client we decided to use the V-card protocol to create a digital business card that could be scanned by a user and then saved to their phone. This would then link to a backend system that would track any follow up’s with potential buyers and additional web traffic that routed to their local sites from the V-card.",
            how: "Leveraging a self hosted CMS system called cockpit, I was able to quickly provide a low level CMS with all the required functionality for updating and issuing new V-cards. This was then paired with reactJS on the front-end to serve the whole application, both of these were then rolled up into docker containers for easy deployment that could scale in the future if required.",
            success: "The system onboarded over 100 users and immediately saw value in reducing the companies carbon footprint, by reducing the need for printed business card.",
            image: vcard
        },{
            client: "F",
            ask: "The brief was to create a  realistic VR application to perform the initial training with a Forklift.",
            project: "A VR application on its own was already a big ask as we had only just started internally testing the brand new VIVE, and the first issue we ran into was that the controllers are really not good for training on a forklift simulation. We decided to custom build the control unit to replicate exactly what a user would have on the real thing. We then set about designing the application, focusing on the different components that needed to be built as well as the external components that would fit into the application. ",
            how: "Most of the development work was conducted using Unity3D as it had the best VR compatibility and support at the time. This focused on scene creations with objects and controllers for the objects that would take in inputs to affect them inside. Unity had an established physics system that served well for the creation of the forklift and its interactions. In addition to the hardware and application, we also created a custom companion application for the instructor. This was for them to get real time information on the candidates status, an error log for any mistakes they made and there was also a way for them to reset scenarios and change obstacles and routes on the fly. We found during testing that some people experienced severe motion sickness when training on the device, so we added a front and back facing monitor for those trainees who were unable to learn with the VR headset, which meant the training application was truly accessible to everyone. ",
            success: "The client was super happy with the result and the training team switched over immediately integrating it into the training syllabus, with a great response for everyone involved in the training.",
            image: vr1
        },{
            client: "F",
            ask: "After the success of the first project, the client again wanted to create a VR training application, this time for mobile work at height platforms.",
            project: "This time we knew we had to leverage what made the first one so successful and focus on custom hardware that emulated the real thing, as it was this level of immersion and realism that gave the project such value. For this we designed a full 1:1 scale replica which has industrial grade actuators that would move and vibrate based on the user's input. We also utilized a new feature from the quest 2, hand tracking. As the platform required lots of different buttons, controls and inputs it was important for the user to be able to see their hands in the virtual space so they could line it up with the correct controls. ",
            how: "Working with 3rd party companies we got a full metal cage build and mounted the actuators to them. At the same time we began to work in Unity to create the environment and outputs based off user interaction. ",
            success: "The client was super happy with the result, as this machine was expensive, difficult to learn on and potentially dangerous, so being able to give trainees an experience that was close to the real thing really reduced time in learning, and the risk factor involved in training. The other major success we only found out during the user testing, the industrial actuators vibrated and moved so close to the real thing it gave immediate feedback to a user to slow down when using the application, and the lead instructor was shocked when they first tried it out at how much it felt like the real thing. This type of non-verbal feedback is one of the reasons VR is such a good training tool for these applications. ",
            image: vr2
        }
    ]
    return useCases;
}
    
export default UseCases;