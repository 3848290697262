import "./Skills.scss"
function Skills() {
    return (
      <div id="skills" className="skills">
        <div className="skills__inner">
        <h2>SKILLS</h2>
            <ul className="skills__list">
            <li className="skills__list__item">
                <h3>Frontend</h3>
                <ul>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JavaScript</li>
                    <li>Typescript</li>
                    <li>React</li>
                    <li>Jest</li>
                </ul>
            </li>
            <li className="skills__list__item">
                <h3>Backend</h3>
                <ul>
                    <li>PHP</li>
                    <li>Node</li>
                    <li>Express</li>
                    <li>MySQL/Postgress</li>
                    <li>MongoDB</li>
                    <li>GraphQL</li>
                </ul>
            </li>
            <li className="skills__list__item">
                <h3>Operations</h3>
                <ul>
                    <li>Git</li>
                    <li>Docker</li>
                    <li>AWS</li>
                    <li>Azure</li>
                    <li>Selenium</li>
                </ul>
            </li>
            <li className="skills__list__item">
                <h3>Productivity</h3> 
                <ul>
                    <li>Office 365</li>
                    <li>Lucid Chart</li>
                    <li>Photoshop</li>
                    <li>Postman</li>
                    <li>Jira</li>
                    <li>Confluence</li>
                </ul>
            </li>
            <li className="skills__list__item">
                <h3>Standards</h3>
                <ul>
                    <li>WACA 2.1</li>
                    <li>SCRUM</li>
                    <li>Agile</li>
                    <li>Detectify</li>
                    <li>OWASP TOP 10</li>
                    <li>Google Lighthouse</li>
                </ul>
            </li>
            <li className="skills__list__item">
                <h3>Consultancy</h3>
                <ul>
                    <li>RFP</li>
                    <li>Audits</li>
                    <li>Pre-sales</li>
                    <li>Solutions Architecture</li>
                    <li>Technical Strategy</li>
                    <li>Pen-testing</li>
                </ul>
            </li>
            </ul>
        </div>
      </div>  
    );
};
export default Skills;